a {
	color: #0366d6;
}
body {
	background-color: #fff;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol', 'Noto Color Emoji' !important;
}
code {
	color: #e01a76;
}
.btn-primary {
	color: #fff;
	background-color: #1b6ec2;
	border-color: #1861ac;
}

.btn-giris {
	width: 100%;
	height: 45px;
	margin-top: 18px;
	border-radius: 5px;
	background: #4e2fb5;
	border: none;
	color: #fff;
	padding: 10px;
	display: block;
	text-align: center;
}

.btn-giris:hover,
.btn-giris:active,
.btn-giris:focus {
	background: #32117c;
	color: #fff;
	text-decoration: none;
}

.bg-gradient-info-new {
	background: -webkit-linear-gradient(50.41deg, rgba(50, 0, 115, 1) 0%, rgba(41, 171, 226, 1) 100%);
	background: -webkit-gradient(linear, 17.46% 86.18%, 85.89% 10.09%, color-stop(0, rgba(50, 0, 115, 1)), color-stop(1, rgba(41, 171, 226, 1)));
	background: -o-linear-gradient(50.41deg, rgba(50, 0, 115, 1) 0%, rgba(41, 171, 226, 1) 100%);
	background: -ms-linear-gradient(50.41deg, rgba(50, 0, 115, 1) 0%, rgba(41, 171, 226, 1) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#320073', endColorstr='#29ABE2' ,GradientType=0)";
	background: linear-gradient(39.59deg, rgba(50, 0, 115, 1) 0%, rgba(41, 171, 226, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#320073',endColorstr='#29ABE2' , GradientType=1);
	min-height: 100vh;
}
.logo-wrapper img {
	max-width: 200px;
}

.splash-badge-wrapper {
	text-align: center;
}

.splash-badge-wrapper img {
	max-width: 50%;
	min-width: 300px;
}

.splash-wrapper > img {
	max-width: 100%;
	width: 500px;
}

.splash-wrapper {
	text-align: center;
}

.footer-link-wrapper {
	position: absolute;
	bottom: 10px;
	right: 20px;
}

.footer-link-wrapper a {
	color: #fff;
	text-decoration: none;
	margin: 0 5px;
}

.header-title {
	font-family: 'Oswald', sans-serif;
}

.step-header-icon-wrapper {
	max-width: 64px;
	display: inline-block;
}

.step-header-icon {
	max-width: 100%;
	width: 64px;
	height: 64px;
}

.pre-step-btn {
	background-color: white;
	border: #6300e2 1px solid;
	color: #6300e2;
}
.next-step-btn {
	background-color: #4a22ce;
	border: none;
	color: white;
	outline: none;
}
.next-step-btn:hover,
.next-step-btn:focus {
	background-color: #3d2d73;
	color: white;
}
.color-orange {
	color: #ff5722;
}

.collapse-list-item-details .card-body {
	background-color: #f1f4ff;
	color: #000;
	font-size: 15px;
	font-weight: 300;
}

.collapse-list-item {
	border: 1px solid #ddd;
	padding: 15px;
	background-color: #eee;
	cursor: pointer;
	font-weight: bold;
	background: linear-gradient(39.59deg, rgba(50, 0, 115, 1) 0%, rgb(41, 72, 226) 100%);
	color: #fff;
	background: -webkit-linear-gradient(50.41deg, rgba(50, 0, 115, 1) 0%, rgba(41, 72, 226, 1) 100%);
	background: -webkit-gradient(linear, 17.46% 86.18%, 85.89% 10.09%, color-stop(0, rgba(50, 0, 115, 1)), color-stop(1, rgba(41, 72, 226, 1)));
	background: -o-linear-gradient(50.41deg, rgba(50, 0, 115, 1) 0%, rgba(41, 72, 226, 1) 100%);
	background: -ms-linear-gradient(50.41deg, rgba(50, 0, 115, 1) 0%, rgba(41, 72, 226, 1) 100%);
	-ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#320073', endColorstr='#2948E2' ,GradientType=0)";
	background: linear-gradient(39.59deg, rgba(50, 0, 115, 1) 0%, rgba(41, 72, 226, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#320073',endColorstr='#2948E2' , GradientType=1);
}

.collapse-list-item:before {
	font-family: fontAwesome;
	content: '●';
	margin-right: 5px;
}

button.as-link {
	background: transparent;
	border: none;
	color: #fff;
}

button.as-link:hover,
button.as-link:active {
	color: #ddd;
}
.top-menu button.as-link {
	background: transparent;
	border: none;
	color: #333;
}
.top-menu button.as-link:hover,
.top-menu button.as-link:active {
	color: #777;
}

.step-navigator-wrapper {
	text-align: right;
}

button.reset-step-btn.btn-up {
	display: inline-block;
}

button.reset-step-btn.btn-down {
	display: none;
}

.modal-body {
	max-height: calc(100vh - 250px);
	overflow-y: auto;
}

.modal-header h5.modal-title {
	font-size: 18px;
	font-weight: bold;
}

.top-menu {
	font-size: 10pt;
}
.top-menu.invisible-mobile {
	display: block;
}
.top-menu.visible-mobile {
	display: none;
}

.table-breaked {
	table-layout: fixed;
	background-color: #fff;
}
.table-breaked td {
	word-wrap: break-word;
	white-space: normal;
}
.table-breaked b {
	font-weight: bold;
}

@media screen and (max-width: 425px) {
	.table-breaked {
		table-layout: auto;
	}
}
@media screen and (max-width: 980px) {
	.top-menu.invisible-mobile {
		display: none;
	}
	.top-menu.visible-mobile {
		display: block;
	}
	.splash-wrapper > img {
		max-width: 90%;
		width: 500px;
	}

	.splash-badge-wrapper img {
		max-height: 50px;
	}

	.bg-gradient-info-new {
		min-height: auto;
	}

	.footer-link-wrapper {
		bottom: 5px;
		font-size: 8pt;
		width: 100%;
		text-align: right;
		left: 0;
		right: 0;
	}
	.footer-version {
		bottom: 5px;
		font-size: 8pt;
		text-align: left;
		left: 0;
		right: 0;
	}

	.splash-wrapper {
		margin-bottom: 40px;
	}

	.dashboard-card {
		padding: 20px 5px !important;
	}

	.header-title {
		font-size: 1.25rem;
	}

	.step-header-icon {
		width: 48px;
		height: 48px;
	}
	.step-navigator-wrapper {
		text-align: center;
	}

	button.reset-step-btn.btn-up {
		display: none;
	}

	button.reset-step-btn.btn-down {
		display: block;
	}
}
.footer-version {
	position: absolute;
	bottom: 10px;
	left: 20px;
	color: #fff;
}
.guide-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
@media (max-width: 425px) {
	.guide-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		padding-bottom: 20px;
	}
}

.tetkikBilgileri p {
	font-weight: 400;
}
.documentList p a {
	font-weight: 500;
	text-transform: uppercase;
}
.left-side-body {
	text-align: center;
	margin-bottom: 3rem;
	margin-top: 3rem;
}
.hasta-kabul-protokol.table {
	margin-top: 3rem;
}
.hasta-kabul-protokol th {
	font-size: 1rem;
	white-space: initial;
}
.red-title {
	font-weight: 500;
	font-size: 1rem;
}
.dipnot {
	background-color: #e8e6e6;
	padding: 5px 11px;
	border-radius: 7px;
	font-weight: 500;
	margin-left: 6px;
}
.goSection1 {
	padding: 0;
	background: none;
	border: 0;
}
.goSection1:before {
	content: none;
}
.loading-content {
	min-height: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.general-loading {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
p {
	font-weight: 400;
}
sup {
    margin-right: 5px;
}
